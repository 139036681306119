import { flow, Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"
import { IAnipetRep, IClient, IFollow } from "../types"
import { numericDateToStringFormat } from "../utils/formatter"
import { orderDateRangeStartDefault, datePickerMaxDate } from "../utils/date-defaults"
import { EUserRole } from "../types"

export const UserModel = types
  .model("UserModel")
  .props({
    id: types.identifierNumber,
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    address: types.maybeNull(types.string),
    state: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    phoneNumber: types.maybeNull(types.string),
    anipetRep: types.maybeNull(types.frozen<IAnipetRep>()),
    erpCustomer: types.maybeNull(types.frozen<IClient>()),
    role: types.enumeration(Object.values(EUserRole)),
    follows: types.array(types.frozen<IFollow>()),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    get isRep() {
      return self.role == EUserRole.vendorRep || self.role == EUserRole.salesRep
    },
    get isCustomer() {
      return self.role == EUserRole.customer
    },
    get isSecondaryUser() {
      return self.role == EUserRole.secondaryUser
    },
    isFollowingProduct(product) {
      return self.follows.map((follow) => follow.followableId).includes(product.id)
    },
    // used to display Your Anipet Rep sidebar menu on my-account
    // allow customers and vendor_reps to see this
    get allowedToViewYourAnipetSideBarMenu() {
      return self.role == EUserRole.customer || self.role == EUserRole.secondaryUser || self.role == EUserRole.vendorRep
    },
  }))
  .views((self) => ({
    get canAddToCart() {
      return !self.isRep || self.erpCustomer
    },
    get name() {
      return `${self.firstName} ${self.lastName}`
    },
    get number() {
      return self.erpCustomer?.number
    },
  }))
  .actions((self) => ({
    setCurrentClient: flow(function* (clientId?: number) {
      self.environment.api.setClientHeader(clientId)
      const response = yield self.environment.api.setCurrentClientV2(clientId)
      if (response && response.ok) {
        self.erpCustomer = response.data
        self.rootStore.catalogueStore.updateSuggestedProducts(response.data.suggestedProducts || [])
        self.rootStore.cartStore.setCart(response.data.cart)
        self.rootStore.salesOrderStore.fetchSalesOrders({
          orderDateGteq: numericDateToStringFormat(orderDateRangeStartDefault()),
          orderDateLteq: numericDateToStringFormat(datePickerMaxDate()),
        })
        sessionStorage.setItem("sessionSavedId", response.data.number)
        //if the customer is blocked, the backend will rais a meta error message
      }
      return response
    }),
    clearCurrentClient: flow(function* () {
      const response = yield self.environment.api.clearCurrentClient()
      if (response && response.ok) {
        self.erpCustomer = null
        self.environment.api.clearClientHeader()
        self.rootStore.catalogueStore.updateSuggestedProducts([])
        self.rootStore.cartStore.clearCart()
      }
      return response
    }),
    addFollow(follow: IFollow) {
      self.follows.push(follow)
    },
  }))

export interface IUser extends Instance<typeof UserModel> {}
