import { NavBar } from "./navbar"
import { Footer } from "./footer"
import * as Sentry from "@sentry/react"
import { SentryErrorFallback } from "./shared/sentry-error-fallback"
import { Flex, Spacer } from "@chakra-ui/react"
import { useEffect } from "react"
import { useMst } from "../../lib/hooks/use-mst"
import NoSSR from "react-no-ssr"

export const Layout = ({ children }) => {
  const { uiStore } = useMst()

  function handleWindowSizeChange() {
    uiStore.setIsMobileDevice(window.innerWidth <= 829)
  }
  useEffect(() => {
    uiStore.setIsMobileDevice(window.innerWidth <= 829)
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  return (
    <NoSSR>
      <Flex minH="100vh" h="full" flexDirection="column" maxW={{ sm: "100vw", md: undefined }}>
        <Sentry.ErrorBoundary
          fallback={SentryErrorFallback}
          showDialog
          beforeCapture={(scope) => {
            scope.setTag("area", "navbar")
          }}
        >
          <NavBar />
        </Sentry.ErrorBoundary>
        <Sentry.ErrorBoundary
          fallback={SentryErrorFallback}
          showDialog
          beforeCapture={(scope) => {
            scope.setTag("area", "page")
          }}
        >
          <main style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>{children}</main>
        </Sentry.ErrorBoundary>
        <Sentry.ErrorBoundary
          fallback={SentryErrorFallback}
          showDialog
          beforeCapture={(scope) => {
            scope.setTag("area", "footer")
          }}
        >
          <Spacer />
          <Footer />
        </Sentry.ErrorBoundary>
      </Flex>
    </NoSSR>
  )
}
