import { flow, Instance, types } from "mobx-state-tree"
import { translate } from "../app/i18n"
import { deleteCredentials, loadCredentials, saveCredentials } from "../app/services"
import { withEnvironment, withRootStore } from "../lib"
import { LoginFormModel } from "../models"

export const SessionStoreModel = types
  .model("SessionStoreModel")
  .props({
    isLoggedIn: types.optional(types.boolean, false),
    loginForm: types.optional(LoginFormModel, {}),
    expiry: types.maybeNull(types.number),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .actions((self) => ({
    login: flow(function* (email: string, password: string) {
      const { api } = self.environment
      try {
        const response: any = yield api.login(email, password)
        if (response.ok) {
          self.rootStore.userStore.setCurrentUser(response.data.data)
          // Fetch cart after login to populate the cart count if applicable
          self.rootStore.cartStore.fetchCart()
          self.isLoggedIn = true
          self.expiry = parseInt(response.headers.expiry)
        } else {
          //log an error message
          self.rootStore.uiStore.flashMessage.show(
            translate("login.failedTitle"),
            translate("login.failedMessage"),
            "danger",
            {},
          )
        }
      } catch (e) {}
    }),
    clearSession() {
      self.isLoggedIn = false
      self.rootStore.userStore.clearCurrentUser()
      self.rootStore.cartStore.clearCart()
    },
    sendForgotPasswordEmail: flow(function* (email) {
      yield self.environment.api.sendForgotPasswordEmail(email)
    }),
  }))
  .actions((self) => ({
    logout: flow(function* () {
      self.clearSession()
      yield self.environment.api.logout()
    }),
    validateToken: flow(function* () {
      const { api } = self.environment
      try {
        const response: any = yield api.validateToken()

        if (response.ok) {
          self.rootStore.userStore.setCurrentUser(response.data.data)
          self.environment.api.setClientHeader(response.data.data.erpCustomer?.id)
          // Fetch cart after login to populate the cart count if applicable
          self.rootStore.cartStore.fetchCart()
          self.isLoggedIn = true
          self.expiry = parseInt(response.headers.expiry)
        } else {
          self.clearSession()
        }
        return response
      } catch {
        // error messaging handled by API monitor
        return false
      }
    }),
    submitLoginForm: flow(function* () {
      self.rootStore.uiStore.showWaitingModal()
      const response = yield self.login(self.loginForm.email, self.loginForm.password)
      self.rootStore.uiStore.hideWaitingModal()
      if (response && response.ok) {
        self.loginForm.password = ""
        return true
      } else {
        return false
      }
    }),
  }))

export interface ISessionStore extends Instance<typeof SessionStoreModel> {}
