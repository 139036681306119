import { Instance, types } from "mobx-state-tree"
import { IProduct } from "../types"
import { OrderTemplateModel } from "./order-template"

export const OrderTemplateItemModel = types
  .model("OrderTemplateItemModel", {
    id: types.identifierNumber,
    orderTemplate: types.reference(types.late(() => OrderTemplateModel)),
    product: types.frozen<IProduct>(),
    quantity: types.number,
    checked: types.optional(types.boolean, false),
    isUpdating: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setQuantity(newQuantity: number) {
      self.quantity = newQuantity
    },
    setIsUpdating(val: boolean) {
      self.isUpdating = val
    },
    toggleChecked() {
      self.checked = !self.checked
    },
  }))

export interface IOrderTemplateItem extends Instance<typeof OrderTemplateItemModel> {}
