import { Text, Flex, Tag, Box } from "@chakra-ui/react"
import { observer } from "mobx-react"
import { useMst } from "../../../../lib/hooks/use-mst"

interface IProductInventoryIndicatorProps {
  locationQoh: any
}
export const ProductInventoryIndicator = observer(({ locationQoh }: IProductInventoryIndicatorProps) => {
  const getInventoryStyles = (locationQoh) => {
    let backgroundColor, textColor

    // sales reps see actual numbers and customers/vendors see strings "<10", ">99", and actual numbers in between
    if (locationQoh === "< 10" || locationQoh < 10) {
      backgroundColor = "background.lowIndicator"
      textColor = "brand.lowIndicator"
    } else {
      backgroundColor = "background.highIndicator"
      textColor = "brand.highIndicator"
    }
    return { backgroundColor, textColor }
  }
  const { backgroundColor, textColor } = getInventoryStyles(locationQoh)

  return (
    <Tag
      bg={backgroundColor}
      color={textColor}
      px={1}
      borderRadius="md"
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Text fontSize="sm">{`${locationQoh}  `}</Text>
      <Text fontSize="xs" pl={1}>
        {" Left"}
      </Text>
    </Tag>
  )
})
