import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Center,
  Box,
} from "@chakra-ui/react"
import React, { useState } from "react"
import { ScanIcon } from "../../../../../public/images/icon/scan-icon"
import dynamic from "next/dynamic"
import { useMst } from "../../../../lib/hooks/use-mst"
import posthog from "posthog-js"

const BarcodeScannerComponent = dynamic(() => import("react-qr-barcode-scanner"), { ssr: false })

type TBarcodeScannerModal = {
  renderTrigger?: (props) => JSX.Element
  onScan: (upc: string) => any
}

export const BarcodeScannerModal = ({ renderTrigger, onScan }: TBarcodeScannerModal) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { barcodeStore, uiStore } = useMst()
  const [frontFacing, setFrontFacing] = useState(false)

  const handleClickOpen = (e) => {
    posthog.capture("open_scanner_modal", {
      userAgent: window.navigator.userAgent,
      isMobileDevice: uiStore.isMobileDevice,
    })
    // Prevents modal from opening (bubbling to parent)
    barcodeStore.setScanInProgress()
    e.stopPropagation()
    onOpen()
  }

  return (
    <>
      {renderTrigger ? (
        renderTrigger({ onClick: handleClickOpen })
      ) : (
        <Button
          _hover={{ cursor: "pointer", background: "gray.100" }}
          bg="white"
          display="flex"
          flexWrap="wrap"
          aria-label="barcode-icon"
          w={10}
          onClick={handleClickOpen}
          leftIcon={<ScanIcon h="24px" w="29px" ml="9px" mt="2px" />}
          fontSize="xs"
        >
          SCAN
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Scan a Barcode</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box position="relative" h={290} overflow="hidden">
              <Box position="absolute">
                <BarcodeScannerComponent
                  facingMode={frontFacing ? "user" : "environment"}
                  width={500}
                  height={500}
                  onUpdate={(err, result) => {
                    if (result && Object.keys(result).length !== 0) {
                      //@ts-ignore
                      onScan(result.text)
                      onClose()
                    }
                  }}
                />
              </Box>
              <Box className="overlay" position="absolute" top={{ base: -6, md: 0 }} left={{ base: -10, md: 0 }}>
                <ViewfinderSVG />
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Center h="full" w="full">
              <Button variant={"outline"} mr={3} onClick={() => setFrontFacing((state) => !state)}>
                Switch Camera
              </Button>
              <Button variant={"outline"} mr={3} onClick={onClose}>
                Close
              </Button>
            </Center>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

const ViewfinderSVG = () => (
  <svg
    fill="rgb(0,0,0,0.5)"
    height="300px"
    width="405px"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 463 463"
  >
    <g>
      <path
        d="M36.403,166.737c4.142,0,7.5-3.358,7.5-7.5v-57.527h57.532c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5H36.403
      c-4.142,0-7.5,3.358-7.5,7.5v65.027C28.903,163.379,32.261,166.737,36.403,166.737z"
      />
      <path
        d="M426.596,86.709h-65.032c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h57.532v57.527c0,4.142,3.358,7.5,7.5,7.5
      s7.5-3.358,7.5-7.5V94.209C434.096,90.067,430.738,86.709,426.596,86.709z"
      />
      <path
        d="M426.596,296.258c-4.142,0-7.5,3.358-7.5,7.5v57.527h-57.532c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h65.032
      c4.142,0,7.5-3.358,7.5-7.5v-65.027C434.096,299.616,430.738,296.258,426.596,296.258z"
      />
      <path
        d="M101.435,361.285H43.903v-57.527c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v65.027c0,4.142,3.358,7.5,7.5,7.5h65.032
      c4.142,0,7.5-3.358,7.5-7.5S105.577,361.285,101.435,361.285z"
      />
    </g>
  </svg>
)
