import { flow, Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"
import {
  ISpecialEquipmentCode,
  ProductCategoryModel,
  ServiceItemGroupModel,
  SpecialEquipmentCodeModel,
} from "../models"
import { NavItem } from "../app/components/shared/nav/nav-items"
import { WEB_ORDER_TYPE } from "../utils/constants"
import { IOtherPageModel, ISystemSetting, IProduct, IBlogPostModel, IInstagramImageModel } from "../types/types"
import { rootStore } from "../app/setup"
import * as R from "ramda"
import { values } from "mobx"

export const CatalogueStoreModel = types
  .model("CatalogueStoreModel")
  .props({
    instagramImages: types.array(types.frozen<IInstagramImageModel>()),
    desktopImageUrls: types.array(types.string),
    isLoading: types.maybeNull(types.boolean),
    announcements: types.array(types.frozen()),
    productCategories: types.array(ProductCategoryModel),
    specialEquipmentCodeMap: types.map(SpecialEquipmentCodeModel),
    serviceItemGroupMap: types.map(ServiceItemGroupModel),
    otherPages: types.array(types.frozen<IOtherPageModel>()),
    blogPosts: types.array(types.frozen<IBlogPostModel>()),
    systemSetting: types.frozen<ISystemSetting>(),
    suggestedProducts: types.array(types.frozen<IProduct>()),
    amountForFreeFrozenShipping: types.maybeNull(types.number),
    amountForNonFreeFrozenShipping: types.maybeNull(types.number),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    get specialEquipmentCodes() {
      //@ts-ignore
      return values(self.specialEquipmentCodeMap) as ISpecialEquipmentCode[]
    },

    get serviceItemGroups() {
      //@ts-ignore
      return values(self.serviceItemGroupMap) as IServiceItemGroup[]
    },
  }))
  .views((self) => ({
    get filteredSuggestedProduct() {
      //for some reason, occassionally the back end (due to caching or some sort of mismatch) can include a deleted product in the name and think it is not blocked
      return self.suggestedProducts.filter(
        (sp) => !sp.blocked && !sp.blockedBrand && !sp.comingSoon && sp?.name && !sp?.name?.startsWith("DELETED"),
      )
    },
    get memberAccessOtherPages() {
      return self.otherPages.filter((op) => op.memberAccess)
    },
    get nonMemberAccessOtherPages() {
      return self.otherPages.filter((op) => !op.memberAccess)
    },
    get splitDesktopImageUrls() {
      const arr = self.desktopImageUrls
      const middleIndex = Math.floor(arr.length / 2) // Find the middle index of the array
      const firstHalf = arr.slice(0, middleIndex) // Slice the first half of the array
      const secondHalf = arr.slice(middleIndex) // Slice the second half of the array
      return [firstHalf, secondHalf] // Return both halves as an array
    },
    get childCategories() {
      return self.productCategories.map((c) => c.childCategories).flat()
    },
    get parentCount() {
      return self.productCategories?.length || 0
    },
    get categoryMenu(): NavItem[] {
      const mapCategory = (c) => {
        return {
          key: c.id,
          label: c.name,
          imageUrl: c.imageUrl,
          href: `/categories/${c.slug || c.id}`,
          children: c.childCategories ? c.childCategories.map((ch) => mapCategory(ch)) : null,
        }
      }
      return self.productCategories.map((c) => mapCategory(c)) || []
    },
    get promotionsPlusNavItem(): NavItem {
      const navItems = {
        key: "promotions-plus",
        href: "/promotions",
        label: "Promotions+",
        fontSize: "20px",
        color: "brand.orange",
        fontWeight: "bold",
        children: [
          ...self.serviceItemGroups
            .filter((sig) => sig.orderType == null || sig.orderType == WEB_ORDER_TYPE)
            .map((sig) => {
              return {
                key: sig.code.toLowerCase(),
                label: sig.description,
                href: `/service-item-groups/${sig.slug || sig.id}`,
              }
            }),
        ],
      }
      if (self.rootStore.sessionStore.isLoggedIn) {
        navItems.children.unshift({
          key: "promotions",
          label: "Promotions",
          href: "/promotions",
          // subLabel: 'This is how you do a sub-label'
        })
      }
      return navItems
    },

    get specialEquipmentCodeNavItems(): NavItem[] {
      return self.specialEquipmentCodes
        .map((sec) => {
          return (
            sec.showInNav && {
              key: sec.code.toLowerCase(),
              label: sec.description,
              href: `/special-equipment-codes/${sec.slug || sec.id}`,
            }
          )
        })
        .filter((e) => e) as NavItem[]
    },
  }))
  .views((self) => ({
    get footerList() {
      let items = [
        ...self.nonMemberAccessOtherPages
          .filter((op) => op.showInFooter)
          .map((op) => {
            return {
              key: op.slug,
              label: op.name,
              href: `/info/${op.slug}`,
            }
          }),
      ]
      if (rootStore.sessionStore.isLoggedIn) {
        items = items.concat(
          ...self.memberAccessOtherPages
            .filter((op) => op.showInFooter)
            .map((op) => {
              return {
                key: op.slug,
                label: op.name,
                href: `/info/${op.slug}`,
              }
            }),
        )
      }
      return items
    },
    get infoNavItem(): NavItem {
      const items = {
        key: "nav-info",
        label: "Info",
        children: [
          ...self.nonMemberAccessOtherPages
            .filter((op) => op.showInMenu)
            .map((op) => {
              return {
                key: op.slug,
                label: op.name,
                href: `/info/${op.slug}`,
              }
            }),
          {
            key: "brand-list",
            label: "Vendor List",
            href: "/info/vendor-list",
          },
          {
            key: "teams",
            label: "Meet the Team",
            href: "/teams",
          },
          {
            key: "become-a-partner",
            label: "Become a Partner",
            href: "/become-a-partner",
          },
        ],
      }
      if (rootStore.sessionStore.isLoggedIn) {
        items.children = items.children.concat(
          ...self.memberAccessOtherPages.map((op) => {
            return {
              key: op.slug,
              label: op.name,
              href: `/info/${op.slug}`,
            }
          }),
        )
      }
      return items
    },
    getCategory(id: string | number) {
      return (
        self.productCategories.find((c) => c.id == id || c.slug == id) ||
        self.childCategories.find((c) => c.id == id || c.slug == id)
      )
    },
    getServiceItemGroup(id: string) {
      return self.serviceItemGroupMap.get(id)
    },
    getSpecialEquipmentCode(id: string) {
      return self.specialEquipmentCodeMap.get(id)
    },
  }))
  .actions((self) => ({
    setSpecialEquipmentCodes(secs) {
      R.forEach((obj) => self.specialEquipmentCodeMap.put(obj), secs)
    },
    setServiceItemGroups(sigs) {
      R.forEach((obj) => self.serviceItemGroupMap.put(obj), sigs)
    },
  }))
  .actions((self) => ({
    load: flow(function* () {
      self.isLoading = true
      const response = yield self.environment.api.fetchCatalogue()
      if (response && response.ok && response.data) {
        // Grabbing categories, annoucements and desktopimageurls from app home screen content
        self.productCategories = response.data.categories
        self.setSpecialEquipmentCodes(response.data.specialEquipmentCodes)
        self.setServiceItemGroups(response.data.serviceItemGroups)
        self.announcements = response.data.announcements
        self.desktopImageUrls = response.data.desktopImageUrls || []
        self.instagramImages = response.data.instagramImages || []
        self.otherPages = response.data.otherPages
        self.blogPosts = response.data.blogPosts
        self.systemSetting = response.data.systemSettings
        self.suggestedProducts = response.data.suggestedProducts
        self.isLoading = false
      }
    }),
    updateSuggestedProducts: flow(function* (products = []) {
      self.suggestedProducts = products
    }),
    setSpecialEquipmentCode(sec) {
      self.specialEquipmentCodeMap.put(sec)
    },
    setServiceItemGroup(sig) {
      self.specialEquipmentCodeMap.put(sig)
    },
  }))

export interface ICatalogueStore extends Instance<typeof CatalogueStoreModel> {}
