import {
  Box,
  Flex,
  Text,
  Button,
  Stack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  Image,
  Center,
  Divider,
  MenuButton,
  Menu,
  MenuList,
  SimpleGrid,
  useDisclosure,
  usePopoverContext,
  Portal,
  Container,
} from "@chakra-ui/react"
import { useRouter } from "next/router"
import { ShoppingCartIcon } from "../../../../../public/images/icon/shopping-cart-icon"
import { HumanIcon } from "../../../../../public/images/icon/human-icon"
import { ArrowDownIcon } from "../../../../../public/images/icon/arrow-down-icon"
import { SearchBar } from "../search/search-bar"
import NextLink from "next/link"
import { useMst } from "../../../../lib/hooks/use-mst"
import { formatAsPrice, integerToPrice } from "../../../../utils/formatter"
import { NavItem } from "./nav-items"

import { ChevronRightIcon } from "@chakra-ui/icons"
import { SelectClient } from "./select-client"
import { observer } from "mobx-react"
import { LinkButton } from "../ui/link-button"
import { BrandButton } from "../ui"
import { AccountMenu } from "./account-menu"
import { NotificationDrawer } from "./notification-drawer"

export const DesktopHeader = observer(({ useLargeHeader, cartProductCount }) => {
  const {
    cartStore,
    userStore,
    sessionStore: { isLoggedIn },
  } = useMst()
  const router = useRouter()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleGoToCart = () => {
    router.push("/shopping-cart")
  }

  const menuButtonText = useLargeHeader
    ? `${cartProductCount} PRODUCT(S)`
    : integerToPrice(cartStore?.cart?.estimatedSubtotal)

  return (
    <Flex
      id="DesktopHeaderHeader"
      bg={useColorModeValue("white", "gray.800")}
      color={useColorModeValue("gray.600", "white")}
      borderBottom={1}
      borderStyle={"solid"}
      borderColor={useColorModeValue("gray.200", "gray.900")}
      flexDirection={"column"}
    >
      <Container maxW="container.xl">
        {useLargeHeader && (
          <Flex flexDirection={"row-reverse"}>
            <Flex align="center">
              {userStore?.currentUser?.isRep && <SelectClient />}
              {!isLoggedIn && (
                <>
                  <LinkButton
                    key={"logged-out-login"}
                    px={3}
                    href={`/login?nextPage=${encodeURIComponent(window.location.href)}`}
                  >
                    Login
                  </LinkButton>
                </>
              )}
              <Divider key={"menu-divider-1"} h={5} orientation="vertical" borderColor="grey.500" />
              {isLoggedIn && <NotificationDrawer />}
              <Divider key={"menu-divider-2"} h={5} orientation="vertical" borderColor="grey.500" />
              <AccountMenu />
            </Flex>
          </Flex>
        )}
        <Flex justifyContent={"space-between"} alignItems={"center"} gap={"1rem"} p={useLargeHeader ? 4 : 1}>
          <NextLink href="/" passHref>
            <Image w={useLargeHeader ? 150 : 16} src="/images/logo/anipet-logo.svg" alt="anipet logo" />
          </NextLink>
          <SearchBar />
          <Flex className="profile-cart-header" justify="center" align="center" gap={1} position="relative">
            {!useLargeHeader && <AccountMenu />}
            {isLoggedIn && (
              <Menu key={"logged-in-cart-menu"} isOpen={isOpen && cartProductCount > 0}>
                <MenuButton
                  as={Button}
                  _hover={{ bg: useColorModeValue("gray.100", "gray.700") }}
                  fontWeight="normal"
                  onMouseEnter={onOpen}
                  onMouseLeave={onClose}
                  onClick={handleGoToCart}
                  leftIcon={<ShoppingCartIcon w={useLargeHeader ? 7 : 5} h={5} color={"brand.orange"} />}
                  variant={"ghost"}
                  aria-label={"Cart"}
                  fontFamily={"Pathway Gothic One"}
                  justifyContent={"center"}
                >
                  <Text fontSize="26px" pl="2">
                    {menuButtonText}
                  </Text>
                </MenuButton>

                <Portal>
                  <MenuList p={5} w="400px" onMouseEnter={onOpen} onMouseLeave={onClose} zIndex={15}>
                    <Flex>
                      <Box w="170px" bgColor="">
                        <Text fontSize="26px">Shopping Cart</Text>
                      </Box>
                      <Box w="50px"></Box>
                      <Box w="140px" pt={1} bgColor="" textAlign="right">
                        <Text color="gray.400">Estimated subtotal:</Text>
                        <Text fontSize="16px" fontWeight="400" pl={2}>
                          {integerToPrice(cartStore?.cart?.estimatedSubtotal)}
                        </Text>
                      </Box>
                    </Flex>
                    <Flex pt={4} pb={3}>
                      <Box>
                        <Text color="gray.400" fontSize="16px">
                          Unit Total: {cartStore?.cart?.unitTotalCount}
                          &nbsp;units of&nbsp;
                          {cartProductCount}
                          &nbsp;products
                        </Text>
                      </Box>
                    </Flex>
                    <Divider />
                    <Flex justify="space-between" pt={4} w="full">
                      <LinkButton icon={<ChevronRightIcon w={7} h={6} />} href="/shopping-cart" pt={3}>
                        View Shopping Cart
                      </LinkButton>
                      <BrandButton href="/checkout" icon={<ChevronRightIcon color="white" w={7} h={6} />}>
                        CHECKOUT
                      </BrandButton>
                    </Flex>
                    <Flex pt={4} w="full" maxH="275px" overflowY="auto">
                      <Box overflowY="auto" bgColor="background.brandGray3b" w="400px" p={2} pl={3}>
                        <Text color="gray.400" fontSize="16px">
                          Last added products to your shopping cart:
                        </Text>
                        {}
                        {(cartStore.cart?.cartItems || []).map((cartItem) => {
                          return (
                            <Box bgColor="background.brandGray3b" key={cartItem.id}>
                              <Flex pt={3}>
                                <Box>
                                  <Image
                                    src={cartItem?.product?.thumbImageUrls?.[0] || cartItem?.product?.imageUrls?.[0]}
                                    alt="product image"
                                    w="60px"
                                    h="60px"
                                  ></Image>
                                </Box>
                                <Box w="10px"></Box>
                                <Box w="230px" pt={2} bgColor="">
                                  <Text fontWeight="700" fontSize="14px">
                                    {cartItem?.product?.navisionDescription}
                                  </Text>
                                  <Text fontSize="16px" color="gray.400">
                                    x {cartItem?.quantity}
                                  </Text>
                                </Box>
                                <Box w="5px" bgColor=""></Box>
                                <Box textAlign="right" pt={2}>
                                  <Text>{integerToPrice(cartItem.unitPrice)}</Text>
                                  <Text color="gray.400" fontSize="14px">
                                    /Piece
                                  </Text>
                                  <Box></Box>
                                </Box>
                              </Flex>
                              <Box pt={2}>
                                <Divider />
                              </Box>
                            </Box>
                          )
                        })}
                      </Box>
                    </Flex>
                  </MenuList>
                </Portal>
              </Menu>
            )}
          </Flex>
        </Flex>
      </Container>
    </Flex>
  )
})

export const DesktopNav = ({ navItems }: { navItems: NavItem[] }) => {
  const linkColor = useColorModeValue("gray.600", "gray.200")
  const popoverContentBgColor = useColorModeValue("white", "gray.800")
  const router = useRouter()

  return (
    <Box id="DesktopNav" w="full" bg="background.brandGray3b" zIndex={1} px={{ base: 3, lg: 0 }} boxShadow="md">
      <Stack direction="row" spacing={3} w="full" justify="center" align="center" top={55} wrap="wrap" p={3}>
        {navItems &&
          navItems.map((navItem) => {
            const buttonProps = {
              p: 0,
              pr: 2,
              h: "24px",
              fontSize: navItem.fontSize || "16px",
              fontWeight: navItem.fontWeight || 500,
              color: navItem.color || linkColor,
              _hover: {
                textDecoration: "none",
                color: "brand.orange",
                cursor: "pointer",
              },
            }
            return (
              <Box maxW="70.5rem" key={navItem.key}>
                <Popover trigger={"hover"} placement={"bottom-start"}>
                  <PopoverTrigger>
                    {navItem.href ? (
                      <Button as={NextLink} href={navItem.href} {...buttonProps}>
                        {navItem.label}
                      </Button>
                    ) : (
                      <Button {...buttonProps}>{navItem.label}</Button>
                    )}
                  </PopoverTrigger>
                  {navItem.children && navItem.children.length > 0 && (
                    <Portal>
                      <PopoverContent
                        border={0}
                        boxShadow={"xl"}
                        bg={popoverContentBgColor}
                        p={4}
                        rounded={"xl"}
                        key={"child-popover"}
                        minW={"2xl"}
                      >
                        <SimpleGrid minChildWidth="120px" columns={Math.floor(Math.sqrt(navItem.children.length))}>
                          {navItem.children.map(({ key, ...rest }) => (
                            <DesktopSubNav key={key} {...rest} />
                          ))}
                        </SimpleGrid>
                        {navItem.imageUrl && <Image src={navItem.imageUrl} />}
                      </PopoverContent>
                    </Portal>
                  )}
                </Popover>
              </Box>
            )
          })}
      </Stack>
    </Box>
  )
}

export const DesktopSubNav = (props: NavItem) => {
  const { onClose } = usePopoverContext()

  return (
    <Box id="DesktopNavDesktopSubNav">
      <NextLink href={props.href} passHref>
        <Button
          variant="link"
          role={"group"}
          p={2}
          rounded={"md"}
          onClick={onClose}
          _hover={{ bg: useColorModeValue("orange.50", "gray.900") }}
        >
          <Stack direction={"row"} align={"center"}>
            <Box>
              <Text w="full" transition={"all .3s ease"} fontWeight={500}>
                {props.label}
              </Text>
            </Box>
            <Flex
              opacity={0}
              _groupHover={{ opacity: "full", transform: "translateX(0)" }}
              justify={"flex-end"}
              align={"center"}
              flex={1}
            ></Flex>
          </Stack>
        </Button>
      </NextLink>
    </Box>
  )
}
