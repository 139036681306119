import { Center, CenterProps } from "@chakra-ui/react"

interface IDottedBoxProps extends CenterProps {
  children: React.ReactNode
}

export const DottedBox = ({ children, ...rest }: CenterProps) => {
  return (
    <Center
      color="gray.400"
      fontSize="md"
      border="1px dashed"
      borderColor="gray.400"
      w="160px"
      h="45px"
      borderRadius="base"
      {...rest}
    >
      {children}
    </Center>
  )
}
