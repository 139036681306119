import { ChevronDownIcon } from "@chakra-ui/icons"
import {
  Box,
  Text,
  Spinner,
  Flex,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  Portal,
  BoxProps,
  Center,
} from "@chakra-ui/react"
import { observer } from "mobx-react"
import React, { useEffect } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useMst } from "../../../../lib/hooks/use-mst"
import { IOrderTemplate } from "../../../../models/order-template"

interface OrderTemplateSelectProps extends BoxProps {
  selectedOrderTemplate: IOrderTemplate
  compact?: boolean
  forSavedCart: boolean
}

export const OrderTemplateSelect = observer(
  ({ selectedOrderTemplate, compact, forSavedCart, ...boxProps }: OrderTemplateSelectProps) => {
    const {
      orderTemplateStore,
      userStore: {
        currentUser: { erpCustomer },
      },
    } = useMst()

    const { isLoading, savedCarts, orderTemplates, setCurrentSavedCart, setCurrentOrderTemplate } = orderTemplateStore

    const onSetSelectedTemplateId = forSavedCart ? setCurrentSavedCart : setCurrentOrderTemplate

    const getNextOrderTemplatesPage = forSavedCart
      ? orderTemplateStore.getNextSavedCartsPage
      : orderTemplateStore.getNextOrderTemplatesPage

    const savedTemplates = forSavedCart ? savedCarts : orderTemplates

    const hasMoreOrderTemplatePages = forSavedCart
      ? orderTemplateStore.hasMoreSavedCartPages
      : orderTemplateStore.hasMoreOrderTemplatePages

    const getInitialOrderTemplatesPage = forSavedCart
      ? orderTemplateStore.getInitialSavedCartsPage
      : orderTemplateStore.getInitialOrderTemplatesPage

    const boxId = forSavedCart ? "savedCartScrollableBox" : "orderTemplateScrollableBox"

    useEffect(() => {
      const fetchInitialOrderTemplates = async () => {
        getInitialOrderTemplatesPage()
      }
      fetchInitialOrderTemplates()
    }, [erpCustomer]) //if the client is changed, trigger a refresh of order templates

    if (isLoading) {
      return (
        <Center w="full" h={28}>
          <Spinner color="brand.orange" />
        </Center>
      )
    }

    return (
      <Box w="full" bg={"background.brandGray3a"} my={6} p={4} {...boxProps}>
        {!(savedTemplates.length > 0) ? (
          <Text textAlign="left" color="gray.600">
            {`No existing ${forSavedCart ? "rep saved carts" : "order templates"}`}
          </Text>
        ) : (
          <Menu>
            {!compact && (
              <Text textAlign="left" color="gray.600">
                {forSavedCart ? "Rep Saved Cart:" : "Template:"}
              </Text>
            )}
            <MenuButton
              pl={3}
              color="gray.500"
              bg="white"
              border="1px solid #D6D6D6"
              w="full"
              textAlign="left"
              h="50px">
              <Flex justify="space-between">
                {selectedOrderTemplate && <TemplateOption orderTemplate={selectedOrderTemplate} />}
                <ChevronDownIcon h={6} w={8} />
              </Flex>
            </MenuButton>
            <Portal>
              <MenuList w="full" minW={400} zIndex="9999">
                <Flex id={boxId} overflow="auto" h={200} direction="column">
                  <InfiniteScroll
                    dataLength={savedTemplates.length} //This is important field to render the next data
                    next={getNextOrderTemplatesPage}
                    hasMore={hasMoreOrderTemplatePages}
                    style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
                    loader={<Spinner color="brand.orange" />}
                    scrollableTarget={boxId}
                    endMessage={<Text textAlign="center">end of list</Text>}>
                    {savedTemplates.map((opt, i) => (
                      // TODO: How to make the menu items full width?
                      <MenuItem key={opt.id} w="full" onClick={() => onSetSelectedTemplateId(opt.id)}>
                        <TemplateOption orderTemplate={opt} />
                      </MenuItem>
                    ))}
                  </InfiniteScroll>
                </Flex>
              </MenuList>
            </Portal>
          </Menu>
        )}
      </Box>
    )
  },
)

interface ITemplateOptionProps {
  orderTemplate: IOrderTemplate
}

export const TemplateOption = observer((props: ITemplateOptionProps) => {
  const { orderTemplate: ot } = props
  return (
    <Flex justify="space-between" w="full">
      <Text>
        <strong>{ot.name}</strong>
        {` (${ot.orderTemplateItems?.length} products)`}
      </Text>
    </Flex>
  )
})
