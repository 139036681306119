import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons"
import { Button, Flex, Text } from "@chakra-ui/react"
import { observer } from "mobx-react"
import { useMemo } from "react"
import { useMst } from "../../lib/hooks/use-mst"
import {
  generatePages,
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
} from "../../third-party/ajna/pagination/src"

interface IPaginatorProps {
  page: number
  totalPages: number
  handlePageChange: (any) => void
  resultsCount: number
}

export const Paginator = observer(({ page, totalPages, handlePageChange, resultsCount }: IPaginatorProps) => {
  const { uiStore } = useMst()
  const { isMobileDevice } = uiStore

  const pages = useMemo(
    () =>
      generatePages({
        currentPage: page,
        pagesCount: totalPages,
        innerLimit: isMobileDevice ? 1 : 2,
        outerLimit: isMobileDevice ? 1 : 2,
      }),
    [page, totalPages, isMobileDevice],
  )

  return (
    <Flex flexDirection="column" alignItems={"center"} mt={2}>
      <Pagination isDisabled={false} currentPage={page} pagesCount={totalPages} onPageChange={handlePageChange}>
        <PaginationContainer align="center" justify="center" style={{ maxWidth: isMobileDevice ? "280px" : "500px" }}>
          <PaginationPrevious
            mr={3}
            borderRadius={"2px"}
            bgColor="background.brandOrange"
            color="background.white"
            w={10}
            h={10}
            _selected={{ color: "background.brandGray1", bgColor: "#F2F2F2" }}
            _hover={{ bgColor: "background.brandOrange" }}>
            <ChevronLeftIcon w={5} h={5} />
          </PaginationPrevious>
          <PaginationPageGroup>
            {pages.map((page: number) => (
              <PaginationPage
                key={`pagination_page_${page}`}
                page={page}
                px={3}
                bgColor="background.white"
                color="gray.500"
                fontWeight="normal"
                _active={{ color: "gray.500" }}
                _selected={{ color: "gray.500" }}
                _hover={{ bgColor: "#FFFFFF" }}
                _current={{ color: "background.brandOrange", fontWeight: "bold" }}
              />
            ))}
          </PaginationPageGroup>
          <PaginationNext
            mr={3}
            borderRadius={"2px"}
            bgColor="background.brandOrange"
            color="background.white"
            w={10}
            h={10}
            _selected={{ color: "background.brandGray1", bgColor: "#F2F2F2" }}
            _hover={{ bgColor: "background.brandOrange" }}>
            <ChevronRightIcon w={5} h={5} />
          </PaginationNext>
        </PaginationContainer>
      </Pagination>
      <Text color="gray.400" fontSize="18px">
        {resultsCount} Item(s) found
      </Text>
    </Flex>
  )
})
