import { applySnapshot, flow, getSnapshot, Instance, types } from "mobx-state-tree"
import { translate } from "../app/i18n"
import { withEnvironment, withRootStore } from "../lib"
import { IOrderTemplate, OrderTemplateModel } from "../models/order-template"

export const OrderTemplateStoreModel = types
  .model("OrderTemplateStoreModel")
  .props({
    orderTemplatesMap: types.map(OrderTemplateModel),
    savedCartsMap: types.map(OrderTemplateModel),
    orderTemplatesPage: types.optional(types.number, 1),
    totalOrderTemplatesPages: types.maybeNull(types.number),
    savedCartsPage: types.optional(types.number, 1),
    totalSavedCartsPages: types.maybeNull(types.number),
    currentOrderTemplate: types.maybeNull(types.safeReference(OrderTemplateModel)),
    currentSavedCart: types.maybeNull(types.safeReference(OrderTemplateModel)),
    isLoading: types.optional(types.boolean, false),
    isRefreshing: types.optional(types.boolean, false),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    get orderTemplates(): IOrderTemplate[] {
      return Array.from(self.orderTemplatesMap.values())
    },
    get savedCarts(): IOrderTemplate[] {
      return Array.from(self.savedCartsMap.values())
    },

    getOrderTemplate(orderTemplateId: number | null): IOrderTemplate | undefined {
      if (orderTemplateId === null) return undefined
      return self.orderTemplatesMap.get(orderTemplateId.toString())
    },
    getSavedCart(savedCartId: number | null): IOrderTemplate | undefined {
      if (savedCartId === null) return undefined
      return self.savedCartsMap.get(savedCartId.toString())
    },
  }))
  .views((self) => ({
    get hasMoreOrderTemplatePages(): boolean {
      return self.totalOrderTemplatesPages === null || self.orderTemplatesPage < self.totalOrderTemplatesPages
    },
    get hasMoreSavedCartPages(): boolean {
      return self.totalSavedCartsPages === null || self.savedCartsPage < self.totalSavedCartsPages
    },
  }))
  .actions((self) => ({
    fetchOrderTemplates: flow(function* () {
      return yield self.environment.api.fetchOrderTemplatesV2(self.orderTemplatesPage, "template")
    }),
    fetchSavedCarts: flow(function* () {
      return yield self.environment.api.fetchOrderTemplatesV2(self.savedCartsPage, "saved_cart")
    }),
    createTemplate: flow(function* (name: string, productNumber?: string, quantity?: number) {
      const templateName = name || `New Order Template ${new Date().toISOString()}`
      const response = yield self.environment.api.createOrderTemplateV2({
        name: templateName,
        productNumber,
        quantity,
        orderTemplateType: "template",
      })
      if (response.ok && response.data) {
        self.orderTemplatesMap.set(response.data.id.toString(), response.data)
        self.rootStore.uiStore.flashMessage.show(null, translate("orderTemplate.saved"), "success", {})
        return true
      } else {
        self.rootStore.uiStore.flashMessage.show(null, translate("orderTemplate.failed"), "error", {})
        return false
      }
    }),
    createSavedCart: flow(function* (name: string, productNumber?: string, quantity?: number) {
      const cartName = name || `New Rep Saved Cart ${new Date().toISOString()}`
      const response = yield self.environment.api.createOrderTemplateV2({
        name: cartName,
        productNumber,
        quantity,
        orderTemplateType: "saved_cart",
      })
      if (response.ok && response.data) {
        self.savedCartsMap.set(response.data.id.toString(), response.data)
        self.rootStore.uiStore.flashMessage.show(null, translate("orderTemplate.savedCart"), "success", {})
        return true
      } else {
        self.rootStore.uiStore.flashMessage.show(null, translate("orderTemplate.savedCartFailed"), "error", {})
        return false
      }
    }),
    deleteOrderTemplate: flow(function* (orderTemplate: IOrderTemplate) {
      if (!orderTemplate) return
      const response = yield self.environment.api.deleteOrderTemplateV2(orderTemplate.id)
      if (response.ok) {
        if (orderTemplate.orderTemplateType === "saved_cart") {
          self.savedCartsMap.delete(orderTemplate.id.toString())
        } else {
          self.orderTemplatesMap.delete(orderTemplate.id.toString())
        }
        self.rootStore.uiStore.flashMessage.show(null, translate("orderTemplate.deleted"), "success", {})
      } else {
        self.rootStore.uiStore.flashMessage.show(null, translate("orderTemplate.deleteFailed"), "error", {})
      }
    }),
  }))
  .actions((self) => ({
    setCurrentOrderTemplate(orderTemplate?: IOrderTemplate) {
      self.currentOrderTemplate = orderTemplate
    },
    setCurrentSavedCart(savedCart?: IOrderTemplate) {
      self.currentSavedCart = savedCart
    },
  }))
  .actions((self) => ({
    createOrderTemplateFromCart: flow(function* (name: string) {
      yield self.createTemplate(name)
    }),
    createSavedCartFromCart: flow(function* (name: string) {
      yield self.createSavedCart(name)
    }),

    setIsLoading(value: boolean) {
      self.isLoading = value
    },
    setIsRefreshing(value: boolean) {
      self.isRefreshing = value
    },
    getInitialOrderTemplatesPage: flow(function* () {
      self.orderTemplatesPage = 1
      self.isLoading = true
      const response = yield self.fetchOrderTemplates()
      if (response.ok && response.data) {
        self.orderTemplatesMap.clear()
        response.data.orderTemplates.forEach((template: IOrderTemplate) => {
          self.orderTemplatesMap.set(template.id.toString(), template)
        })
        self.setCurrentOrderTemplate(response.data.orderTemplates?.[0]?.id)
        self.totalOrderTemplatesPages = response.data.totalPages
        self.isLoading = false
        return true
      }
      self.isLoading = false
      return false
    }),
    getNextOrderTemplatesPage: flow(function* () {
      if (!self.hasMoreOrderTemplatePages) return
      self.isLoading = true
      self.orderTemplatesPage++
      const response = yield self.fetchOrderTemplates()
      if (response.ok && response.data) {
        self.orderTemplatesMap.clear()
        response.data.orderTemplates.forEach((template: IOrderTemplate) => {
          self.orderTemplatesMap.set(template.id.toString(), template)
        })
      }
      self.isLoading = false
    }),
    getInitialSavedCartsPage: flow(function* () {
      self.savedCartsPage = 1
      self.isLoading = true
      const response = yield self.fetchSavedCarts()
      if (response.ok && response.data) {
        self.savedCartsMap.clear()
        response.data.orderTemplates.forEach((cart: IOrderTemplate) => {
          self.savedCartsMap.set(cart.id.toString(), cart)
        })
        self.setCurrentSavedCart(response.data.orderTemplates?.[0]?.id)
        self.totalSavedCartsPages = response.data.totalPages
        self.isLoading = false
        return true
      }
      self.isLoading = false
      return false
    }),
    getNextSavedCartsPage: flow(function* () {
      if (!self.hasMoreSavedCartPages) return
      self.isLoading = true
      self.savedCartsPage++
      const response = yield self.fetchSavedCarts()
      if (response.ok && response.data) {
        self.savedCartsMap.clear()
        response.data.orderTemplates.forEach((cart: IOrderTemplate) => {
          self.savedCartsMap.set(cart.id.toString(), cart)
        })
      }
      self.isLoading = false
    }),
  }))

export interface IOrderTemplateStore extends Instance<typeof OrderTemplateStoreModel> {}
