import { extendTheme } from "@chakra-ui/react"
import { colors } from "./foundations/colors"
import { withProse } from "@nikolovlazar/chakra-ui-prose"

declare global {
  interface Window {
    dataLayer: Record<string, any>[]
  }
}

const overrides = {
  styles: {
    // This is a hack needed to prevent the html root element from rendering
    // smaller than it says it is on mobile only (or simulated in dev tools)
    // (mobile viewport does not match the actual pixel area shown).
    // No idea why that happens but this seems to fix it. Note that the HTML tag width will actually
    // claim to be larger than the viewport width on mobile. Weird stuff.
    global: {
      html: {
        minW: { base: "fit-content" },
      },
    },
  },
  colors,
  fonts: {
    body: "Cabin Condensed",
    heading: "Cabin Condensed",
    label: "Pathway Gothic One",
  },
  fontWeight: {
    regular: 400,
    medium: 500,
    semibold: 700,
  },
  fontSize: {
    "2xs": "0.625rem", //10px
    xs: ".75rem", //12px
    sm: ".875rem", //14px
    base: "1rem", //16px
    lg: "1.125rem", //18px
    xl: "1.25rem", //20px
    "2xl": "1.25rem", //20px
    "3xl": "1.5rem", //24px
    "4xl": "1.875rem", //30px
    "5xl": "2.25rem", //36px
    "6xl": "3rem", //48px
  },

  components: {
    Input: {
      baseStyle: {
        field: {
          color: "gray.500",
          _placeholder: {
            color: "gray.300",
          },
        },
      },
    },
    Heading: {
      baseStyle: {
        fontWeight: "normal",
      },
    },
  },
}

export const theme = extendTheme(overrides, withProse())
