export const colors = {
  // Palette generation https://smart-swatch.netlify.app/
  brand: {
    orange: "#F2724E",
    blueSecondary: "#0099CC",
    primary: {
      50: "#FFFACE",
      500: "#FFE500", // base
    },
    secondary: {
      50: "#c4deed",
      500: "#4C8BAD",
      800: "#064E73",
      900: "#003049",
    },
    contrast: {
      orange: "#FFA249",
      blue: "#6DEDFC",
      green: "#6FCF97",
      yellow: "#F6C416",
    },
    lowIndicator: "#F2724E",
    mediumIndicator: "#0099CC",
    highIndicator: "#339E18",
  },
  border: {
    orange: "#cc3300",
  },
  background: {
    white: "#FFFFFF",
    dark: "#002C43",
    lighter: "#FBFCFE",
    light: "#F5F9FF",
    heroBlue: "linear-gradient(180deg, #002C43 0%, #0E5B84 100%)",
    gradientDark: "linear-gradient(180deg, #002C43 0%, #0D5073 100%);",
    brandBlueSecondary: "#0099CC",
    brandGray1: "#808080",
    brandGray2: "#BBBBBB",
    brandGray3a: "#F0F0F0",
    brandGray3b: "#F7F7F7",
    brandOrange: "#F2724E",
    orange: "#FFA249",
    lighterBlack: "#111111",
    black: "#000000",
    beige: "#FEEBCB",
    lightGreen: "#C6F6D5",
    lowIndicator: "rgba(242, 114, 78, 0.12)",
    mediumIndicator: "rgba(0, 153, 204, 0.13)",
    highIndicator: "rgba(51, 158, 24, 0.15)",
    lightBlue: "#F4F9FC",
    // teal: "linear-gradient(308.66deg, #178D9E -21.9%, #003049 90.7%)",
    // yellow: "rgba(255, 229, 0, 0.4)",
    // orange: "rgba(255, 162, 73, 0.5)",
    // red: "rgba(255, 35, 35, 0.3)",
    // green: "rgba(101, 247, 45, 0.4)",
    // blue: "rgba(109, 237, 252, 0.5)",
    // purple: "rgba(92, 16, 255, 0.3)",
  },

  gray: {
    100: "#F6F6F6",
    200: "#DDDDDD",
    300: "#AAAAAA",
    400: "#777777",
    500: "#333333",
    lighten: {
      50: "rgba(255, 255, 255, 0.2)",
      100: "rgba(255, 255, 255, 0.5)",
      200: "rgba(255, 255, 255, 0.7)",
      300: "rgba(255, 255, 255, 0.9)",
    },
  },
  utility: {
    link: "#4BB3FD",
    danger: "#FF1053",
    success: "#5FAD41",
    warning: "#F2724E",
  },
}
