import { flow, Instance, types } from "mobx-state-tree"
import { values, toJS } from "mobx"
import { translate } from "../app/i18n"
import { withEnvironment, withRootStore } from "../lib"
import { CartModel, ICart, ICartItem, ICartInProgressItem, CartInProgressItemModel } from "../models"
import * as R from "ramda"
import posthog from "posthog-js"
import { ECartMenuFilters } from "../types"

export const ALL_MENU_FILTER_CATEGORIES = Object.values(ECartMenuFilters).join(",")

export const CartStoreModel = types
  .model("CartStoreModel")
  .props({
    cart: types.maybeNull(CartModel),
    isSubmitting: types.optional(types.boolean, false),
    isLoading: types.optional(types.boolean, false),
    // this is used to store a temporary cart for handling quantities before items are added to the cart
    cartItemsInProgressMap: types.map(CartInProgressItemModel),
    // same as above, but for the products added from the notification panel
    notificationCartItemsInProgressMap: types.map(CartInProgressItemModel),
    updateInProgress: types.optional(types.boolean, false),
    pricingInProgress: types.optional(types.boolean, false),
    deletingInProgress: types.optional(types.boolean, false),
    filterValue: types.optional(types.string, ALL_MENU_FILTER_CATEGORIES),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    get cartItemsInProgress(): ICartInProgressItem[] {
      //@ts-ignore
      return values(self.cartItemsInProgressMap) as ICartInProgressItem[]
    },
    get notificationCartItemsInProgress(): ICartInProgressItem[] {
      //@ts-ignore
      return values(self.notificationCartItemsInProgressMap) as ICartInProgressItem[]
    },
    getQuantityInProgress(itemNumber, notificationCart = false): number {
      const mapToUse = notificationCart ? self.notificationCartItemsInProgressMap : self.cartItemsInProgressMap
      return mapToUse.get(itemNumber)?.quantity || 0
    },
    get productCount() {
      return self.cart?.cartItems?.length || 0
    },
  }))
  .views((self) => ({
    get filteredCartItems(): ICartItem[] {
      if (self.filterValue === ALL_MENU_FILTER_CATEGORIES) {
        return self.cart?.cartItems
      } else {
        const filteredCategoryArray = self.filterValue.split(",")
        return self.cart?.cartItems.filter(
          (ci) =>
            filteredCategoryArray.includes(ci.product.productCategory) ||
            filteredCategoryArray.includes(ci.product.itemLocation),
        )
      }
    },
  }))
  .views((self) => ({
    get filteredBrandVendorNames(): string[] {
      return (
        self.filteredCartItems
          ?.map((cartItem) => cartItem.product.brandVendorName)
          ?.filter((brandVendorName, index, self) => self.indexOf(brandVendorName) === index)
          ?.sort() || []
      )
    },
  }))
  .actions((self) => ({
    setIsSubmitting(isSubmitting: boolean) {
      self.isSubmitting = isSubmitting
    },
    setCart(cart: ICart) {
      self.cart = cart
    },
    setFilterValue(string) {
      self.filterValue = string
    },
    findProductInCart(productNumber: string): ICartItem | undefined {
      return self.cart?.cartItems.find((cartItem) => cartItem.product.number == productNumber)
    },

    emptyCart: flow(function* () {
      {
        const response = yield self.environment.api.removeAllCartItemsV2()
        if (response.ok) {
          self.cart = response.data
          return true
        } else {
          return false
        }
      }
    }),
  }))
  .actions((self) => ({
    fetchCart: flow(function* () {
      try {
        self.isLoading = true
        const response = yield self.environment.api.fetchCartV2()
        if (response.ok) {
          self.setCart(response.data)
        }
      } catch {
        self.isLoading = false
        // error messaging handled by API monitor
      }
      self.isLoading = false
    }),
    addCartItemsV2: flow(function* (cartItems: ICartInProgressItem[]) {
      self.updateInProgress = true
      const response = yield self.environment.api.addCartItemsV2(cartItems)
      self.updateInProgress = false
      self.cart = response.data
      if (response.ok) {
        self.rootStore.uiStore.flashMessage.showCartMessage(
          translate("addToCartButton.title"),
          response.data?.meta?.message || translate("addToCartButton.successMessage"),
          response.data?.meta?.message ? "warning" : "success",
          {},
        )
      }
      return response
    }),
    priceCartV2: flow(function* () {
      self.pricingInProgress = true
      const response = yield self.environment.api.priceCartV2()
      self.cart = response.data
      self.pricingInProgress = false
      if (response.ok) {
        self.rootStore.uiStore.flashMessage.showCartMessage(
          translate("addToCartButton.title"),
          response.data?.meta?.message || translate("priceCart.successMessage"),
          response.data?.meta?.message ? "warning" : "success",
          {},
        )
      }
      return response
    }),
    addMultipleToCart: flow(function* (cartItems: ICartInProgressItem[]) {
      self.updateInProgress = true
      const response = yield self.environment.api.addMultipleToCart(cartItems)
      self.updateInProgress = false
      if (response.ok) {
        self.cart = response.data
        self.rootStore.uiStore.flashMessage.showCartMessage(
          translate("addToCartButton.title"),
          response.data.message || translate("addToCartButton.successMessage"),
          response.data.message ? "warning" : "success",
          {},
        )
        return response
      } else {
        return response
      }
    }),

    addToCartFromOrderTemplate: flow(function* (orderTemplateId: number, replaceCart?: boolean) {
      self.updateInProgress = true
      const response = yield self.environment.api.addToCartFromOrderTemplateV2(orderTemplateId, replaceCart)
      self.updateInProgress = false
      if (response.ok) {
        self.cart = response.data
        self.rootStore.uiStore.flashMessage.showCartMessage(
          translate("addToCartButton.title"),
          response.data?.meta?.message || translate("addToCartButton.successMessage"),
          response.data?.meta?.message ? "warning" : "success",
          {},
        )
        return true
      } else {
        self.rootStore.uiStore.flashMessage.showCartMessage(
          translate("addToCartButton.title"),
          translate("addToCartButton.errorMessage"),
          "error",
          {},
        )
        return false
      }
    }),
    updateCartItem: flow(function* (anipetItemNumber: string, quantity: number) {
      self.updateInProgress = true
      try {
        const response = yield self.environment.api.updateCartItemV2(anipetItemNumber, quantity)
        self.updateInProgress = false
        if (response.ok) {
          self.cart = response.data
          self.rootStore.uiStore.flashMessage.showCartMessage(
            translate("addToCartButton.title"),
            "Cart updated!",
            "success",
            {},
          )
          return true
        } else {
          return false
        }
      } catch {
        self.updateInProgress = false
        // error messaging handled by API monitor
      }
    }),
    removeFromCart: flow(function* (cartItems: ICartItem[]) {
      try {
        self.updateInProgress = true
        const response = yield self.environment.api.removeCartItemsV2(cartItems)
        self.updateInProgress = false
        if (response.ok) {
          self.cart = response.data
          return true
        }
      } catch {
        // error messaging handled by API monitor
        self.updateInProgress = false
      }
      return false
    }),
    clearCart() {
      self.cart = null
    },
    updateCartInProgress(anipetItemNumber: string, quantity: number, notificationCart = false) {
      const mapToUse = notificationCart ? self.notificationCartItemsInProgressMap : self.cartItemsInProgressMap
      try {
        //if updated quantity is 0 remove it
        if (quantity == 0) {
          mapToUse.delete(anipetItemNumber)
        } else {
          //put into object before inserting
          mapToUse.set(anipetItemNumber, {
            productNumber: anipetItemNumber,
            quantity: quantity,
          })
        }
      } catch {
        // error messaging handled by API monitor
      }
    },
  }))
  .actions((self) => ({
    addToCart: flow(function* (productNumber: string, quantity: number) {
      const cartItem = CartInProgressItemModel.create({
        productNumber,
        quantity,
      })

      return self.addCartItemsV2([cartItem])
    }),
    //similar to addAllToCart will be called on the
    //addCartItemsInProgressToCart - append cart items and add them to the cart, if item is already in cart, add the quantity to what's there, call clearcartInProgress
    addCartItemsInProgressToCart: flow(function* (useNotificationCart = false) {
      self.updateInProgress = true
      const arrayToUse = useNotificationCart ? self.notificationCartItemsInProgress : self.cartItemsInProgress
      const mapToUse = useNotificationCart ? self.notificationCartItemsInProgressMap : self.cartItemsInProgressMap
      if (!self.rootStore.userStore.currentUser!.canAddToCart) {
        self.rootStore.uiStore.flashMessage.showCartMessage(
          translate("selectClientWarning.title"),
          translate("selectClientWarning.addToCartMessage"),
          "warning",
          {},
        )
        return false
      }
      const multipleResponse = yield self.addCartItemsV2(arrayToUse)
      if (multipleResponse.ok && !R.isNil(multipleResponse.data)) {
        mapToUse.replace({})
        if (useNotificationCart) {
          self.rootStore.notificationStore.deleteNotificationsByProductArray(arrayToUse)
        }
      } else {
        //do nothing do not empty the cart
      }
      self.updateInProgress = false
      return multipleResponse
    }),
    removeItemsInProgress: flow(function* () {
      self.cartItemsInProgressMap.replace({})
    }),
    checkout: flow(function* (data) {
      try {
        const response = yield self.environment.api.checkoutV2(data.orderNotes, data.externalDocumentNumber)
        if (response.ok) {
          posthog.capture("checkout", {
            username: self.rootStore.userStore.currentUser.email,
            name: self.rootStore.userStore.currentUser.name,
            role: self.rootStore.userStore.currentUser.role,
            cartTotal: self.cart.total,
            cartId: self.cart.id,
          })

          //TODO: replace web with a review service on google or something
          // self.environment.appStoreReview &&
          //   self.environment.appStoreReview.trigger('cartCheckout')
          self.clearCart()
          return response
        } else {
          self.fetchCart()
        }
      } catch {
        // error messaging handled by API monitor
      }
    }),
  }))

export interface ICartStore extends Instance<typeof CartStoreModel> {}
