import { Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"
import { SalesOrderLineModel } from "./"

export const SalesOrderModel = types
  .model("SalesOrderModel")
  .props({
    id: types.identifierNumber,
    number: types.maybeNull(types.string),
    salesOrderLines: types.array(types.late(() => SalesOrderLineModel)),
    orderDate: types.maybeNull(types.string),
    holdForStock: types.maybeNull(types.string),
    prebook: types.maybeNull(types.string),
    presale: types.maybeNull(types.string),
    comingSoon: types.maybeNull(types.string),
    sendWithNext: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    total: types.maybeNull(types.string),
    locationCode: types.maybeNull(types.string),
    billToAddress: types.maybeNull(types.string),
    billToAddress2: types.maybeNull(types.string),
    billToCity: types.maybeNull(types.string),
    billToCountry: types.maybeNull(types.string),
    billToCounty: types.maybeNull(types.string),
    billToCustomerName: types.maybeNull(types.string),
    billToContact: types.maybeNull(types.string),
    billToName: types.maybeNull(types.string),
    billToPostCode: types.maybeNull(types.string),
    shipToAddress: types.maybeNull(types.string),
    shipToAddress2: types.maybeNull(types.string),
    shipToCity: types.maybeNull(types.string),
    shipToCountry: types.maybeNull(types.string),
    shipToCounty: types.maybeNull(types.string),
    shipToCustomerName: types.maybeNull(types.string),
    shipToContact: types.maybeNull(types.string),
    shipToName: types.maybeNull(types.string),
    shipToPostCode: types.maybeNull(types.string),
    sellToContact: types.maybeNull(types.string),
    referenceNumber: types.maybeNull(types.string),
    unitOfMeasureCode: types.maybeNull(types.string),
    salespersonCode: types.maybeNull(types.string),
    shipmentMethodCode: types.maybeNull(types.string),
    tax: types.maybeNull(types.string),
    totalIncludeTax: types.maybeNull(types.string),
    subtotal: types.maybeNull(types.string),
    externalDocumentNumber: types.maybeNull(types.string),
    notes: types.maybeNull(types.array(types.string)),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .actions((self) => ({
    convertStringToYesOrNo(str) {
      if (str == "f") {
        return "No"
      }
      return "Yes"
    },
    calculateTax() {
      self.tax = (Number(self.totalIncludeTax) - Number(self.subtotal)).toString()
    },
    checkAdditionalInfoBoolean() {
      if (
        self.holdForStock == "f" &&
        self.sendWithNext == "f" &&
        self.presale == "f" &&
        self.prebook == "f" &&
        self.comingSoon == "f"
      ) {
        return false
      }
      return true
    },
  }))

export interface ISalesOrderModel extends Instance<typeof SalesOrderModel> {}
