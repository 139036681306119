import { applySnapshot, IMSTArray, types } from "mobx-state-tree"
import * as R from "ramda"
import { withEnvironment, withRootStore } from "../lib"
import { ECreditStatus } from "../types"
import { IItemCredit, ItemCreditModel, ITEM_CREDIT_DEFAULT_STATE } from "./item-credit"

export const CREDIT_DEFAULT_STATE: TCreditData = {}

export const CreditModel = types
  .model("CreditModel")
  .props({
    id: types.maybeNull(types.number),
    notes: types.maybeNull(types.string),
    status: types.optional(types.enumeration(Object.values(ECreditStatus)), ECreditStatus.draft),
    statusLabel: types.optional(types.string, "Draft"),
    referenceNumber: types.maybeNull(types.string),
    navisionIdentifier: types.maybeNull(types.string),
    postedIdentifier: types.maybeNull(types.string),
    statusTimestamp: types.maybeNull(types.string),
    amount: types.maybeNull(types.string),
    itemCredits: types.array(ItemCreditModel),
    submittedAt: types.maybeNull(types.string),
    processedAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    get isValid() {
      return (
        // NOTE: we need to use .length rather than a rambda / third party method in order for self.itemCredits to be observable
        self.itemCredits.length != 0 &&
        R.all((ci: IItemCredit) => ci.isValid, self.itemCredits) &&
        self.rootStore.userStore.currentUser?.isRep &&
        self.rootStore.userStore.currentUser?.erpCustomer
      )
    },
  }))
  .actions((self) => ({
    setNotes(notes: string) {
      self.notes = notes
    },
    reset() {
      applySnapshot(self, CREDIT_DEFAULT_STATE)
    },
    addItemCredit() {
      // temporary identifier so we can remove the item credit later on if requested
      const id = R.isEmpty(self.itemCredits) ? 1 : R.last(self.itemCredits)!.id + 1
      self.itemCredits.push(R.mergeRight(ITEM_CREDIT_DEFAULT_STATE, { id, newRecord: true }))
    },
    removeItemCredit(id: number) {
      self.itemCredits = R.filter((itemCredit: IItemCredit) => itemCredit.id != id, self.itemCredits) as IMSTArray<any>
    },
  }))

type TCreditModel = typeof CreditModel.Type
type TCreditData = typeof CreditModel.CreationType
type TCreditSnapshot = typeof CreditModel.SnapshotType

export interface ICredit extends TCreditModel {}
export interface ICreditData extends TCreditData {}
export interface ICreditSnapshot extends TCreditSnapshot {}
