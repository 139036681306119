import * as humps from "humps"
import * as R from "ramda"

export const debounce = (limit, callback) => {
  let timeoutId
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(callback, limit, args)
  }
}

//add depth max to be 10, likely has to do with d365 nested objects
export const camelizeResponse = (data: { [key: string]: any }) => {
  return humps.camelizeKeys(data, { depth: 10 })
}

export const decamelizeRequest = (params: { [key: string]: any }) => {
  return humps.decamelizeKeys(params, { depth: 10 })
}

export const eqValues: (...args: any[]) => boolean = R.compose(R.isEmpty, R.symmetricDifference)

export const extractNativeTags = (component) => {
  let results: any = []
  const getNativeTags = (el) => {
    if (el._nativeTag) {
      results.push(el._nativeTag)
    } else {
      results.push(el)
    }

    if (el._children) {
      results.concat(el._children.map(getNativeTags))
    }

    return results
  }

  return getNativeTags(component)
}

export const formatOsName = (osName: string) => {
  if (osName.toLowerCase() === "ios") {
    return "iOS"
  } else if (osName.toLowerCase() === "android") {
    return "Android"
  }
}
export const localStorageTest = () => {
  var test = "test"
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

export const priceStringToNumber = (priceString) => {
  if (!priceString) return

  return parseFloat(priceString.replace("$", "").replace(",", ""))
}

export const lineItemDiscountString = (lineItem) => {
  return (
    (lineItem.discountPercent || lineItem.discountAmount) &&
    (lineItem.discountPercent ? `${lineItem.discountPercent.toString()}%` : `$${priceToFixed(lineItem.discountAmount)}`)
  )
}

export const lineItemDiscountedPriceString = (lineItem) => {
  return (
    lineItem.discountPercent
      ? priceStringToNumber(lineItem.product.unitPrice) -
        (lineItem.discountPercent * priceStringToNumber(lineItem.product.unitPrice)) / 100
      : priceStringToNumber(lineItem.product.unitPrice) - lineItem.discountAmount
  ).toFixed(2)
}

export const priceToFixed = (priceStr) => {
  var number = parseFloat(priceStr)
  if (!isNaN(number)) {
    return number.toFixed(2)
  }
  return priceStr
}

// Helper function to parse currency strings like "$12.34" into integer cents (e.g., 1234)
export function priceStringToInteger(value: unknown): number | null {
  if (value == null) return null
  if (typeof value === "number") return value // already a number
  if (typeof value === "string") {
    // Remove dollar signs and commas (keep the decimal point)
    const cleanedValue = value.replace(/[$,]/g, "")
    const floatValue = parseFloat(cleanedValue)
    if (isNaN(floatValue)) return null
    // Multiply by 100 to convert dollars to cents
    return Math.round(floatValue * 100)
  }
  return null
}

// Helper function to transform the product object by converting
// legacy currency strings to integer cents for its price fields.
export function transformLegacyProduct(product: any): any {
  return {
    ...product,
    // Transform unitPrice if it is a legacy string
    unitPrice:
      product.unitPrice != null && typeof product.unitPrice === "string"
        ? priceStringToInteger(product.unitPrice)
        : product.unitPrice,
    // Transform effectiveSalesPrice if it is a legacy string
    effectiveSalesPrice:
      product.effectiveSalesPrice != null && typeof product.effectiveSalesPrice === "string"
        ? priceStringToInteger(product.effectiveSalesPrice)
        : product.effectiveSalesPrice,
  }
}
