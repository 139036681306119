import { flow, Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"
import { IBlogPostModel } from "../types/types"
import * as R from "ramda"
import { EBlogFilterKeys } from "../types/enums"

// Sort options for blog posts
export const BLOG_SORT_OPTIONS = {
  "Date Ascending": "created_at asc",
  "Date Descending": "created_at desc",
  "Title A-Z": "title asc",
  "Title Z-A": "title desc",
}

export const BlogStoreModel = types
  .model("BlogStoreModel")
  .props({
    isLoading: types.maybeNull(types.boolean),
    blogPosts: types.array(types.frozen<IBlogPostModel>()),
    // New property to store pinned posts
    pinnedPosts: types.optional(types.array(types.frozen<IBlogPostModel>()), []),
    blogType: types.optional(types.array(types.string), []),
    dateFrom: types.maybeNull(types.string),
    dateTo: types.maybeNull(types.string),
    page: types.optional(types.number, 1),
    totalPages: types.maybeNull(types.number),
    totalCount: types.maybeNull(types.number),
    sortBy: types.optional(types.string, ""),
    query: types.optional(types.string, ""),
    isSearching: types.maybeNull(types.boolean),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    get allBlogPosts(): IBlogPostModel[] {
      return self.blogPosts
    },
    get publishedBlogPosts(): IBlogPostModel[] {
      return self.blogPosts.filter((post) => post.published)
    },
    get blogPostTypeOptions() {
      // Get possible blog types from the enum instead of just what's in current posts
      const blogTypes = [
        "important",
        "feature",
        "community",
        "holiday",
        "new_items",
        "new_vendor",
        "resource",
        "product_review",
        "price_change",
        "discontinued",
        "deleted",
      ]

      return blogTypes.map((type) => ({
        displayName: type.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase()),
        key: type,
        applied: R.includes(type, self.blogType),
      }))
    },
    get sortOptions() {
      return Object.keys(BLOG_SORT_OPTIONS).map((key) => ({
        key,
        displayName: key,
      }))
    },
    get currentSortOption() {
      return BLOG_SORT_OPTIONS[self.sortBy] || BLOG_SORT_OPTIONS["Date Descending"]
    },
  }))
  .actions((self) => ({
    // Toggle a filter option
    toggleFilterOption(option, filterKey: EBlogFilterKeys) {
      if (filterKey === EBlogFilterKeys.blogType) {
        const isChecked = self[filterKey].includes(option.key)

        if (isChecked) {
          self[filterKey].remove(option.key)
        } else {
          self[filterKey].push(option.key)
        }
      }

      // Reset to page 1 when filters change
      self.page = 1
    },

    // Clear a specific filter
    clearFilterKey(filterKey: EBlogFilterKeys) {
      if (!filterKey) {
        // Clear all filters if no key is provided
        self.blogType.clear()
        self.dateFrom = null
        self.dateTo = null
        self.query = ""
      } else if (filterKey === EBlogFilterKeys.blogType) {
        self.blogType.clear()
      } else if (filterKey === EBlogFilterKeys.date_range) {
        self.dateFrom = null
        self.dateTo = null
      }

      // Reset to page 1 when filters are cleared
      self.page = 1
    },

    // Set date range for filtering
    setDateRange(from: string, to: string) {
      self.dateFrom = from
      self.dateTo = to
      self.page = 1
    },

    // Set search query
    setQuery(query: string) {
      self.query = query
      self.page = 1
    },

    // Set sort option
    setSortBy(sortOption: string) {
      self.sortBy = sortOption
    },

    // Set page without fetching
    setPage(page: number) {
      self.page = page
    },

    // Replace blog type array
    setBlogTypes(types: string[]) {
      self.blogType.replace(types)
    },
  }))
  .actions((self) => ({
    // Main fetch function that gets blog posts and updates the store
    fetchBlogPosts: flow(function* (options = {}) {
      self.isLoading = true
      try {
        // Construct search parameters inline
        const searchParams = {
          // Include active filters
          blogType: self.blogType.length > 0 ? self.blogType.join(",") : undefined,
          dateFrom: self.dateFrom,
          dateTo: self.dateTo,

          // Pagination
          page: self.page,

          // Sort based on selected option
          sort: self.currentSortOption,

          // Published by default
          published: true,

          // Override with any passed options
          ...options,
        }

        // Use the API to get the data
        const response = yield self.environment.api.blogPostSearch(self.query, searchParams)

        if (response.ok && response.data) {
          // Explicitly set the blog posts from the response
          const blogPosts = response.data.blogPosts || []

          // Update the store state with the fetched data
          self.blogPosts.replace(blogPosts)

          // If pinned posts are included in the response, update them too
          if (response.data.pinnedPosts && Array.isArray(response.data.pinnedPosts)) {
            self.pinnedPosts.replace(response.data.pinnedPosts)
          } else {
            // If no pinned posts in response, clear the array
            self.pinnedPosts.clear()
          }

          self.totalCount = response.data.meta?.total || 0
          self.totalPages = Math.ceil(self.totalCount / (response.data.meta?.perPage || 10))
        } else {
          console.warn("Blog posts search response not OK or missing data")
        }

        return response
      } catch (error) {
        console.error("Error fetching blog posts:", error)
        return { ok: false, error }
      } finally {
        self.isLoading = false
      }
    }),
    setPage(page: number) {
      self.page = page || (1 as any)
    },
  }))
  .actions((self) => ({
    resetPage(router) {
      if (self.page !== 1) {
        router.query.page = "1"
        router.push(router)
      }
      self.setPage(1)
    },
    // Initialize with default values and fetch
    initialize: flow(function* () {
      self.page = 1
      self.query = ""
      self.blogType.clear()
      self.dateFrom = null
      self.dateTo = null
      self.sortBy = "Date Descending"

      // Fetch blog posts - pinned posts will be included in response
      return yield self.fetchBlogPosts()
    }),
  }))

export interface IBlogStore extends Instance<typeof BlogStoreModel> {}
