import { useDebouncedCallback } from "use-debounce"
import { Input, Image, Box, Link, ListItem, UnorderedList, Button, Flex } from "@chakra-ui/react"
import { useState, useEffect, RefObject, forwardRef } from "react"
import { observer } from "mobx-react"
import { useMst } from "../../../../lib/hooks/use-mst"
import { IProduct } from "../../../../types/types"
import { BarcodeScannerModal } from "../modals/barcode-scanner-modal"
import { useRouter } from "next/router"
import { DottedBox } from "../../domains/product/dotted-box"

export interface ISearchBarProps {
  renderActions?: (noResults: boolean) => void
  handleTouch?: any
  initialQuery?: string
  styleProps?: any
}

interface ISearchBarState {
  searchText: string
  userIsTyping: boolean
}

const MIN_SEARCHABLE_QUERY_LENGTH = 3

// eslint-disable-next-line react/display-name
export const SearchBar = observer(
  forwardRef((props: ISearchBarProps, ref: RefObject<HTMLInputElement>): JSX.Element => {
    //TODO: add styleprops to override the default
    const { productSearchStore, barcodeStore, uiStore } = useMst()
    const router = useRouter()

    const [searchBarState, setSearchBarState] = useState<ISearchBarState>({
      searchText: props.initialQuery || "",
      userIsTyping: false,
    })

    const debounced = useDebouncedCallback((value) => {
      search(value)
    }, 500)

    useEffect(() => {
      debounced(searchBarState.searchText)
    }, [searchBarState.searchText, debounced])

    const isSearchable = (): boolean => {
      return searchBarState.searchText.length >= MIN_SEARCHABLE_QUERY_LENGTH
    }

    // const handleChangeText = (text: string): void => {
    const handleChangeText = (e): void => {
      setSearchBarState({ searchText: e.target.value, userIsTyping: true })
    }

    const search = async (term: string) => {
      if (isSearchable() && productSearchStore!.quickSearchQuery.trim() !== term.trim()) {
        productSearchStore!.setQuickSearchQuery(term)
        setSearchBarState({ ...searchBarState, userIsTyping: false })
        await productSearchStore!.quickSearch()
      } else if (term.length == 0) {
        // } else if (searchBarState.userIsTyping) {
        await productSearchStore?.resetQuickSearch()
      }
    }

    /**
     * Fetches the current search term stored inside the search bar state
     * & navigates to the search result page with the current search keyword as the query parameter.
     */
    const goToSearchResultPage = (query = null) => {
      const searchText = query || searchBarState.searchText
      // same guard clause as the quick seach results query
      if (searchText.length >= 2) {
        productSearchStore.setQuery(searchText)
        window.location.assign(`/search?q=${searchText}`)
      }
    }

    const goToBarcodeScanPage = async (upc) => {
      barcodeStore.setScannedBarcode(upc)
      const response = await barcodeStore.fetchScannedProduct()
      barcodeStore.resetScanInProgress()
      if (response.ok) {
        router.push(`/products/${response.data.number}`)
      }
    }

    return (
      <Box display="flex" flexGrow={1} alignContent="center" justifyContent="center" p={4}>
        <Flex width="full" maxWidth="full" position="relative" justify="center" alignItems="center" h={12}>
          <Input
            ref={ref}
            placeholder="Product name or item number..."
            h={uiStore.isMobileDevice ? "80%" : "full"}
            w="full"
            borderRadius="5px 0px 0px 5px"
            border="1px solid"
            borderColor="gray.300"
            fontSize={uiStore.isMobileDevice ? "md" : "xl"}
            onChange={handleChangeText}
            value={searchBarState.searchText}
            name="search"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                goToSearchResultPage()
              }
            }}
          />
          <Image
            h={uiStore.isMobileDevice ? "80%" : "full"}
            src="/images/icon/search-icon.svg"
            alt="logo"
            _hover={{ cursor: "pointer" }}
            onClick={() => goToSearchResultPage()}
          />
          <Flex w="50px" ml={2}>
            <BarcodeScannerModal onScan={goToBarcodeScanPage} />
          </Flex>
          <Box
            bgColor="white"
            textAlign="left"
            position="absolute"
            left={0}
            top="50px"
            right="39px" // width of search button
            zIndex={1}
            boxShadow="lg"
          >
            <UnorderedList listStyleType={"none"} margin={0}>
              {productSearchStore.quickSearchProducts.map((product: IProduct) => (
                <ListItem
                  key={product.id}
                  className="highlight-on-hover"
                  p={2}
                  display="flex"
                  alignItems="center"
                  mb={0}
                >
                  <Image
                    h="40px"
                    w="40px"
                    display="inline"
                    src={product.thumbImageUrls?.[0] || product.imageUrls?.[0]}
                    alt="product image"
                  />
                  <Link
                    w="fit-content"
                    href={`/products/${product?.slug || product?.id}`}
                    p={2}
                    color={product.comingSoon ? "gray.300" : "gray.500"}
                  >
                    {product.name} - {product.number}
                  </Link>
                  {product.comingSoon && (
                    <DottedBox fontSize="sm" h="full" w="fit-content" px={2} justifySelf="flex-start">
                      Coming Soon
                    </DottedBox>
                  )}
                  {product.blocked ||
                    (product.blockedBrand && (
                      <DottedBox fontSize="sm" h="full" w="fit-content" px={2} justifySelf="flex-start">
                        Currently Unavailable
                      </DottedBox>
                    ))}
                </ListItem>
              ))}
            </UnorderedList>
            {productSearchStore.quickSearchProducts.length >= 1 && !productSearchStore.isSearching ? (
              <Box pb={3} textAlign="center">
                <Button colorScheme="orange" onClick={() => goToSearchResultPage()}>
                  See More
                </Button>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Flex>
      </Box>
    )
  }),
)
