import { applySnapshot, flow, Instance, types } from "mobx-state-tree"
import { EOrderTemplateType } from "../types/enums"
import { IOrderTemplateItem, OrderTemplateItemModel } from "./order-template-item"
import * as R from "ramda"
import { withEnvironment, withRootStore } from "../lib"
import { translate } from "../app/i18n"

export const OrderTemplateModel = types
  .model("OrderTemplateModel", {
    id: types.identifierNumber,
    orderTemplateType: types.enumeration("EOrderTemplateType", Object.values(EOrderTemplateType)),
    name: types.string,
    updatedAt: types.string,
    orderTemplateItems: types.array(OrderTemplateItemModel),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .actions((self) => ({
    setName(newName: string) {
      self.name = newName
    },
    addOrderTemplateItem: flow(function* (orderTemplateId: number, productNumber: string, quantity: number) {
      const response = yield self.environment.api.createOrderTemplateItemV2(orderTemplateId, productNumber, quantity)
      if (response.ok && response.data) {
        applySnapshot(self, response.data)
        self.rootStore.uiStore.flashMessage.show(null, translate("generic.saved"), "success", {})
      } else {
        self.rootStore.uiStore.flashMessage.show(null, translate("generic.failed"), "error", {})
      }
    }),
    deleteOrderTemplateItem: flow(function* (itemId) {
      const item = self.orderTemplateItems.find((item) => item.id === itemId)
      item.setIsUpdating(true)
      const response = yield self.environment.api.deleteOrderTemplateItemV2(itemId)
      if (response.ok) {
        // Assuming the API returns the updated list after deletion
        if (response.data) {
          applySnapshot(self, response.data)
        } else {
          // If the API doesn't return updated data, manually remove the item
          const itemToRemove = self.orderTemplateItems.find((item) => item.id === itemId)
          if (itemToRemove) {
            self.orderTemplateItems.remove(itemToRemove)
          }
        }
        self.rootStore.uiStore.flashMessage.show(null, "Item deleted", "success", {})
      } else {
        self.rootStore.uiStore.flashMessage.show(null, "Failed to delete item", "error", {})
      }
    }),
    updateOrderTemplateItemQuantity: flow(function* (itemId: number, newQuantity: number) {
      const item = self.orderTemplateItems.find((item) => item.id === itemId)
      const originalQuantity = item.quantity
      if (item) {
        try {
          // Optimistically update the quantity
          item.setIsUpdating(true)
          // Perform the API call
          const response = yield self.environment.api
            .updateOrderTemplateItemV2(itemId, newQuantity)
            .finally(() => item.setIsUpdating(false))

          if (!response.ok) {
            throw new Error("Failed to update item quantity on the backend.")
          }
        } catch (error) {
          console.error(error)
          // Optionally, revert the quantity change or notify the user
          item.setQuantity(originalQuantity)
        }
      } else {
        console.warn(`OrderTemplateItem with id ${itemId} not found.`)
      }
    }),
    updateName: flow(function* (orderTemplateId: number, name: string) {
      const response = yield self.environment.api.updateOrderTemplateV2(orderTemplateId, { name })
      if (response.ok && response.data) {
        applySnapshot(self, response.data)
        return true
      } else {
        self.rootStore.uiStore.flashMessage.show(null, translate("orderTemplate.updateNameFailed"), "error", {})
        return false
      }
    }),
  }))
  .views((self) => ({
    get sortedOrderTemplateItems(): IOrderTemplateItem[] {
      return R.sort((a, b) => b.id - a.id, self.orderTemplateItems || [])
    },
    get isOrderTemplate() {
      return self.orderTemplateType === EOrderTemplateType.template
    },
  }))

export interface IOrderTemplate extends Instance<typeof OrderTemplateModel> {}
