export enum ECreditStatus {
  draft = "draft",
  submitted = "submitted",
  approved = "approved",
  rejected = "rejected",
}

export enum EPromotionStatus {
  setup = "setup",
  preview = "preview",
  published = "published",
}

export enum ECreditType {
  draft = "draft",
  submitted = "submitted",
  processed = "processed",
}

export enum EProductFilterKeys {
  brand = "brand",
  itemClass = "item_class",
  attributes = "attributes",
  productGroup = "product_group",
}

export enum EPromotionFilterKeys {
  brand = "brand",
  tag = "tag",
}

export enum EStatusBarStyle {
  default = "default",
  lightContent = "light-content",
  darkContent = "dark-content",
}

export enum ECartMenuFilters {
  food = "Food",
  supplies = "Supplies",
  frozen = "Frozen",
  surrey = "Surrey",
}

export enum EProductCategories {
  food = "Food",
  supplies = "Supplies",
  frozen = "Frozen",
}

export enum EProductLocations {
  surrey = "Surrey",
  calgary = "Calgary",
  all = "All",
}

export enum EFreightType {
  packaged = "packaged",
  palletized = "palletized",
}

export enum EFollowableTypes {
  product = "Product",
  promotion = "Promotion",
}

export enum EUserRole {
  vendorRep = "vendor_rep",
  salesRep = "sales_rep",
  customer = "customer",
  secondaryUser = "secondary_user",
}

export enum EOrderTemplateType {
  template = "template",
  savedCart = "saved_cart",
}

export enum EProductDiscountType {
  clearancePrice = "clearance_price",
  customerBestPrice = "customer_best_price",
}

export enum EBlogFilterKeys {
  blogType = "blogType",
  date_range = "date_range",
}
