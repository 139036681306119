import { BellIcon } from "@chakra-ui/icons"
import { observer } from "mobx-react"
import { useMst } from "../../../../lib/hooks/use-mst"
import { IProduct } from "../../../../types"
import { Button } from "@chakra-ui/react"

interface IProductFollowButtonProps {
  product: IProduct
}

export const ProductFollowButton = observer(({ product }: IProductFollowButtonProps) => {
  const {
    notificationStore,
    userStore: { currentUser },
  } = useMst()

  const handleClickNotifyMe = () => {
    notificationStore.createProductFollow(product.id)
  }

  const isFollowing = currentUser.isFollowingProduct(product)

  return (
    <Button
      variant="link"
      isDisabled={isFollowing}
      leftIcon={<BellIcon />}
      color="background.brandOrange"
      onClick={handleClickNotifyMe}
    >
      {isFollowing ? "You will be notified" : "Notify Me"}
    </Button>
  )
})
